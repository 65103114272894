import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../config';
import { Link } from 'gatsby';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorMessage = styled.h1`
  font-size: 36px;
  color: ${Colors.PRIMARY};
  text-align: center;
`;

const RedirectText = styled(Link)`
  color: ${Colors.SECONDARY};
  font-size: 18px;
  text-align: center;
`;

const PageNotFound = () => (
  <Container>
    <ErrorMessage>Page Not Found</ErrorMessage>
    <RedirectText to="/">Click here to return home.</RedirectText>
  </Container>
);

export default PageNotFound;
